.productDetails {
  padding: 20px 160px;
}

.sub {
  margin-top: 0px;
  margin-bottom: 40px;
  display: flex;
}
.productDetails .img_wrpr {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.productDetails .main_img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
.productDetails .sub .img_wrpr .imgs {
  margin-top: 10px;
  display: flex;
}
.productDetails .sub .img_wrpr .imgs img {
  width: 15%;
  /* height: 80px; */
  object-fit: contain;
  margin-right: 10px;
  cursor: pointer;
}
.productDetails .dtls_wrpr {
  flex: 2;
}
.productDetails .dtls_wrpr .title2 {

  margin: 0;
  padding: 0;
}
.productDetails .dtls_wrpr .rating_wrpr {
  margin-top: 20px;
  display: flex;
}
.productDetails .dtls_wrpr .rating_wrpr img {
  margin-right: 10px;
}

.productDetails .dtls_wrpr .price_wrpr {
  margin-top: 20px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ecf0f3;
}
.productDetails .dtls_wrpr .price_wrpr * {
  margin-right: 20px;
}

.productDetails .dtls_wrpr .ppt_wrpr {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ecf0f3;
}
.productDetails .dtls_wrpr .ppt_wrpr h3 {
  color: #161d25;
  font-size: 14px;
}
.productDetails .dtls_wrpr .ppt_wrpr .wrpr {
  display: flex;
  flex-wrap: wrap;
}
.productDetails .dtls_wrpr .ppt_wrpr .wrpr > * {
  margin-left: 20px;
}
.productDetails .dtls_wrpr .ppt_wrpr .wrpr .outer {
  border: 1px solid black;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 20px;
}
.productDetails .dtls_wrpr .ppt_wrpr .wrpr .inner {
  width: 26px;
  height: 26px;
  border-radius: 100%;
}

.productDetails .btn_container {
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.productDetails .btn_container .qty_wrpr {
  display: flex;
  align-items: center;
}
.productDetails .btn_container .qty_wrpr > * {
  margin-right: 20px;
}

.productDetails .btn_container .qty_wrpr .qty_btn {
  height: max-content;
  padding: 16px 20px;
  background-color: #f9fafb;
  border: 1px solid #ecf0f3;
  border-radius: 10px;
  cursor: pointer;
}

/* Tab Styles========================================== */

.prod_tab {
  padding: 0px 30px;
  display: flex;
  background-color: #f9fafb;
  color: #959ead;
}
.prod_tab .item {
  margin-right: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.prod_tab .item .iText {
  margin-top: 40px;
  margin-bottom: 20px;
}
.prod_tab .i_pattern {
  width: 0;
  height: 0;
  border-bottom: 13px solid blue;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
}
.prod_tab .share_wrpr {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}
.prod_tab .share_wrpr:hover {
  color: var(--primary-color);
}
/* Decription Styles======================== */

.desc {
}

.desc .desc_sub {
  margin-top: 30px;
}
.desc .desc_sub h3 {
  color: var(--dark-color);
}
.desc .desc_sub .items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 0px;
}
.desc .desc_sub .item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

/* Reviews Style =========================================== */

.reviews {
}
.reviews .over_all {
  display: flex;
}
.reviews .over_all .sub_1 {
  width: 30%;
}
.reviews .sub_1 .rating_wrpr {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.reviews .over_all .sub_1 .rating_wrpr p {
  margin: 0;
  margin-left: 20px;
  color: var(--dark-color);
  font-size: 30px;
  font-weight: 600;
}

.reviews .over_all .sub_1 .rating_wrpr p span {
  color: var(--light-color);
  font-size: 20px;
  font-weight: 400;
}
.reviews .over_all .sub_2 {
  width: 70%;
}
.reviews .over_all .rating_bar_wrpr {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.reviews .over_all .rating_bar_wrpr p {
  margin-right: 10px;
  font-size: 14px;
}
.reviews .over_all .rating_bar_wrpr .bar {
  margin: 0 10px;
  width: 80%;
  height: 4px;
  background-color: #f4f6f8;
  border-radius: 100%;
}

.reviews .over_all .rating_bar_wrp .value {
  color: #5a7184;
}
.reviews .bottom {
  display: flex;
  margin-top: 30px;
}
.reviews .bottom .comments {
  width: 70%;
}
.reviews .bottom .comments .header {
  display: flex;
  justify-content: space-between;
}
.reviews .bottom .comments .header .sort_wrpr {
  display: flex;
  align-items: center;
}

.reviews .bottom .comments .header .sort_wrpr .title3 {
  margin-right: 10px;
  font-weight: bold;
}

.reviews .comment_item {
  margin-bottom: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #ecf0f3;
}

.reviews .comment_item .user_dtls {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.reviews .comment_item .user_dtls img {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: bisque;
}

.reviews .comment_item .user_dtls .sub {
  margin: 0;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.reviews .comment_item span {
  color: var(--primary-color);
  cursor: pointer;
}

.reviews .btn_wrpr {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews .comments .btn {
  cursor: pointer;
  display: flex;
}
.reviews .comments .btn p {
  color: var(--primary-color);
  font-size: 14px;
  margin-left: 10px;
}

.reviews .bottom .add_review {
  height: max-content;
  width: 30%;
  padding: 30px 20px;
  margin-left: 20px;
  box-shadow: 0px 16px 64px rgba(31, 47, 70, 0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.reviews .bottom .add_review .star_wrpr {
  margin-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ecf0f3;
}
.reviews .bottom .add_review .star {
  cursor: pointer;
  margin-right: 10px;
}

.reviews .bottom .add_review .desc_wrpr {
  margin-top: 30px;
}

.reviews .bottom .add_review .desc_wrpr textarea {
  width: 90%;
  margin-top: 10px;
  padding: 10px;
  background-color: #f9fafb;
  border: 1px solid #ecf0f3;
  outline: none;
}

@media only screen and (max-width: 768px) {
  .productDetails {
    padding: 24px;
  }
  .productDetails .sub {
    margin-top: 0px;
    flex-direction: column;
  }
  .productDetails .main_img {
    width: 100%;
    height: 100%;
  }
  .productDetails .dtls_wrpr .title2 {
    margin-top: 10px;
    font-size: 18px;
  }
  .productDetails .dtls_wrpr .price_wrpr {
    margin-top: 0px;
    padding-bottom: 15px;
    align-items: center;
  }
}
