.text_input_wrpr {
  padding: 15px;
  display: flex;
  background: #f9fafb;
  border: 1px solid #ecf0f3;
  border-radius: 5px;
}
.text_input_wrpr .text_input {
    margin-left: 10px;
  outline: none;
  background-color: transparent;
  border: none;
 
}
