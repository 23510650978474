.best_selling {
  padding-top: 25px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #f9fafb;
}
.best_selling h3 {
  color: black;
}

.best_selling .swiper_wrpr {
  width: 80%;
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .best_selling {
    padding: 32px 0px;
  }
  .best_selling .title2 {
    font-size: 30px;
  }
  .best_selling .desc {
    font-size: 14px;
  }
}
