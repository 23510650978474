.container {
    height: 80vh;
    display: flex;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .image {
    width: 40%;
    margin-right: 20px;
    padding: 50px;
  }
  
  .content {
    margin-left: 10%;
    width: 60%;
    height: 70%;
  }
  
  .heading {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 10px;
    /* color: orange; */
  }
  
  .paragraph {
    font-size: 12px;
    margin-bottom: 20px;
    width: 80%;
    font-weight: 500;
  }
  
  .contact {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  
  .icon {
    margin-right: 20px;
  }
  
  .contactText {
    font-size: 16px;
  }
  
  p {
    font-size: 1.2em;
    margin-top: 20px;
  }
  @media only screen and (max-width: 768px) {
.container{
    padding: 10px;
}
.content{
    margin: 0;
    width: 100%;
    text-align: center;
}
.paragraph{
    width: 100%;
}

    .image{
        display: none;
    }

    .contact{
        justify-content: center;
    }
  }