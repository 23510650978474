.primary {
  background-color: var(--primary-color);
  padding: 16px 32px;
  width: max-content;
  height: max-content;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary {
  padding: 14px;
  height: max-content;
  width: max-content;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  cursor: pointer;
}

.secondary img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}
@media only screen and (max-width: 768px) {
.primary{
  padding: 14px;
}
}