.arrow_container {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow_container .l_arrow {
  margin-right: 20px;
  cursor: pointer;
}

.arrow_container .r_arrow {
  padding: 16px;
  background-color: var(--primary-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
