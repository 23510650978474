.cust_choices {
  padding: 80px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9fafb;
}
.cust_choices .prdts_wrpr {
  margin: 50px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  align-items: center;
  justify-content: center;
}
.cust_choices .prdt_card {
  height: 100px;
  padding: 5px;
  display: flex;
  background-color: #ffffff;
  border-radius: 12px;
  cursor: pointer;
}
.cust_choices .prdt_card > img {
  width: 100px;
  object-fit: cover;
}
.prdt_card .txt_wrpr {
  /* background: red; */
  /* margin-top: -50px; */
  margin-left: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0;
}
.prdt_card .sub {
  width: 100%;
  /* display: flex; */
}
.prdt_card .txt_wrpr .title3 {
  /* margin-top: -10px; */
  width: 80%;
  margin: 0;
  padding: 0;
}
.prdt_card .txt_wrpr .price {
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0;
  font-size: 12px;
}
.prdt_card .sub img {
  margin-right: 10px;
 
}
@media only screen and (max-width: 768px) {
  .cust_choices {
    padding: 24px;
  }
  .cust_choices .title2 {
    font-size: 30px;
  }
  .cust_choices .desc {
    font-size: 12px;
  }
  .cust_choices .prdts_wrpr {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .cust_choices .prdt_card {
  }
}
