.product_card {
  position: relative;
  background-color: #ffffff;
  padding: 6px;
  border-radius: 14px;
  cursor: pointer;
}
.product_card > img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.product_card .catBar {
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
}
.product_card .catBar img {
  margin-right: 10px;
}
.product_card .fav_ico {
  position: absolute;
  width: 12px;
  height: 12px;
  object-fit: contain;
  top: 10px;
  right: 10px;
  padding: 5px;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
}
