.landing {
}
.landing .banner {
  /* height: 100vh; */
  display: flex;
  background-size: 100% 100%;
}
.landing .banner .txt_wrpr {
  flex: 1;
  padding: 160px 0 0 160px;
}
.landing .banner .tag {
  padding: 10px;
  background-color: #F27EB2;
  width: max-content;
  border-radius: 15px;
  font-size: 14px;
  color: #ffffff;
}
.landing .banner h1 {
  font-size: 50px;
  letter-spacing: -0.05em;
  color: #161d25;
}
.landing .banner p {
  font-size: 15px;
  color: #637381;
}
.landing .banner .btn_wrpr {
  margin-top: 30px;
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.landing .banner .img_wrpr {
  flex: 1;
  padding: 160px 160px 160px 0;
}
.landing .banner .img_wrpr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.landing .feature_wpr {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.landing .feature_wpr .feature {
  padding: 20px 84px 20px 20px;
  border-radius: 12px;
  width: 200px;
}
.landing .feature_wpr .feature img {
  height: 26px;
  width: 26px;
}
.landing .feature_wpr .feature h2 {
  margin-top: 10px;
  font-size: 14px;
}
.landing .feature_wpr .feature p {
  margin-top: 10px;
  font-size: 12px;
  color: #637381;
}
@media only screen and (max-width: 768px) {
  .landing .banner {
    flex-direction: column;
  }
  .landing .banner .txt_wrpr {
    width: 100vw;
    padding: 24px;
  }
  .landing .banner h1 {
    margin-top: 10px;
    font-size: 35px;
  }
  .landing .banner p {
    font-size: 12px;
  }
  .landing .banner .btn_wrpr {
    width: 100%;
  }
  .landing .banner .img_wrpr {
    padding: 24px;
  }
  .landing .feature_wpr {
    padding: 24px;
    height: auto;
    width: auto;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: start;
  }
  .landing .feature_wpr .feature {
    width: 33%;
    padding: 10px;
  }
  .landing .feature_wpr .feature h2{
    font-size: 12px;
  }
}
