body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;

}

html {
  --primary-color: #5468e7;
  --primary-shade: #d1ecfd;
  --secondary-color: #c897e4;
  --secondary-shade: #e8beff;
  --dark-color: #161d25;
  --ink-color: #212b36;
  --light-color: #959ead;
  --lighter-color: #637381;
  --lightest-color: #919eab;

  font-family: Gilroy;
}

.title2 {
  margin-bottom: 8px;
  font-size: 38px;
  color: var(--dark-color);
  font-family: Gilroy;
}
.title3 {
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--dark-color);
  font-weight: bold;
}
.desc {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #959ead;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
    url("./assets/fonts/Gilroy-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
