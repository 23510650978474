/* HelpCenter.css */
.help-container {
    height: 80vh;
    display: flex;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .help-image {
    width: 40%;
    margin-right: 20px;
    padding: 50px;
  }
  
  .help-content {
    margin-left: 10%;
    width: 60%;
    height: 70%;
  }
  
  .help-heading {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 10px;
    /* color: orange; */
  }
  
  .help-paragraph {
    font-size: 12px;
    margin-bottom: 20px;
    width: 80%;
    font-weight: 500;
  }
  
  .help-contact {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  
  .help-icon {
    margin-right: 20px;
  }
  
  .help-contact-text {
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    .help-container{
        padding: 10px;
    }
    .help-image{
       display: none;
    }
    .help-content{
        margin: 0;
        width: 100%;
    }
    .help-paragraph{
        width: 100%;
    }
    .help-contact{
        /* justify-content: center; */
    }
  }