.footer img {
  cursor: pointer;
}
.footer {
  padding: 30px 160px;
  background-color: var(--dark-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer .sub_1 {
  color: white;
}
.footer .sub_1 .logo {
  height: 40px;
}
.footer .sub_1 h1 {
  font-size: 28px;
}
.footer .desc {
  color: #8b8b8b;
  font-size: 14px;
  letter-spacing: -0.03em;
  text-align: center;
}
.footer .tags {
  margin-top: 60px;
  width: 40%;
  display: flex;
  justify-content: space-between;
}
.footer .tags a {
  text-decoration: none;
  color: #8b8b8b;
  font-size: 18px;
}
.footer .line {
  margin: 48px 0;
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}
.footer .btm_wrpr {
  padding-bottom: 25px;
  font-size: 12px;
  color: #adadad;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .btm_wrpr div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;
}
.footer .btm_wrpr div a {
  margin-right: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 30px;
  }
  .footer .tags {
    width: 100%;
  }
  .footer .btm_wrpr{
    flex-direction: column-reverse;
  }
  .footer .btm_wrpr div {
    margin-bottom: 10px;
    width: auto;
  }
}
