.navbar {
  display: flex;
  height: 100px;
  padding: 0 160px;
  display: flex;
  align-items: center;
  background-color: #0e2b20;
  /* background-color: #ffffff; */
  justify-content: space-between;
}
.navbar .logo {
  width: 200px;
  object-fit: contain;
}
.navbar .title {
  color: var(--dark-color);
  font-size: 22px;
}
.navbar .search_wrpr {
  width: 470px;
  margin-left: 30px;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 5px;
  flex: 1;
}
.navbar .searchIco {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-left: 10px;
}
.navbar .search_wrpr input {
  border: none;
  padding-left: 10px;
  outline: none;
  background-color: #f9fafb;
  width: 100%;
  font-size: 14px;
}
.navbar .search_wrpr input::placeholder {
  color: #959ead;
  font-size: 14px;
}
.navbar .last_wrpr {
  display: flex;
  align-items: center;
  justify-self: flex-end;
}
.navbar .last_wrpr .ico {
  width: 20px;
  height: 20px;
  margin-left: 30px;
  cursor: pointer;
}
.navbar .last_wrpr .line {
  width: 1px;
  height: 20px;
  background-color: #dde3e7;
  margin-left: 30px;
}
.navbar .last_wrpr p {
  font-size: 16px;
  font-weight: bold;
  color: #637381;
  cursor: pointer;
}
.navbar .last_wrpr .login {
  margin-left: 30px;
  color: #5468e7;
}
.navbar .last_wrpr .or {
  margin: 0 5px;
  font-weight: normal;
  cursor: default;
}

.navbar ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  color: white;
}
.navbar ul li {
  cursor: pointer;
}
.navbar ul li:hover {
  color: rgb(181, 173, 238);
}
@media only screen and (max-width: 768px) {
  .navbar {
    padding: 20px;
  }
  .navbar ul {
    gap: 5px;
  }
}
